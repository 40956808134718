import React from 'react';
import Layout from '@accrosoft-ltd/vf-careers-site-theme/src/components/Layout';
import WigLoader from '@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader';

export default function HomepagePage_Content(props) {
  const {
    pageContext: { config, contentDataData, contentMetaData },
  } = props;

  const siteConfig = config;

  const companyName =
    siteConfig?.CompanyName || props?.smartfeedCompanyData?.CompanyName || '';

  let internalView = false;
  let iFrameView = false;
  let HideCareersLink = false;

  if (props.urlLocation && props.urlLocation.search) {
    let searchParams = new URLSearchParams(props.urlLocation.search);
    let entries = searchParams.entries();

    for (let pair of entries) {
      if (pair[0] === 'iframe') {
        iFrameView = pair[1];
      }
      if (pair[0] === 'HideCareersLink') {
        HideCareersLink = pair[1];
      }
      if (pair[0] === 'IsIntranet') {
        internalView = pair[1];
      }
    }
  }

  return (
    <Layout
      siteConfig={siteConfig}
      location={props.location}
      apiKey={props.apiKey}
      groupOrIdParam={props.groupOrIdParam}
      smartfeedCompanyData={props.smartfeedCompanyData}
      appGatewayURL={props.appGatewayURL}
      vacanciesRouteLoading={props.vacanciesRouteLoading}
      vacanciesRouteError={props.vacanciesRouteError}
      trackEvent={props.trackEvent}
      title={`${companyName} Careers`}
      description={`Careers at ${companyName}`}
      useGroupVacsLink={props.useGroupVacsLink}
    >
      {!props.vacanciesRouteLoading &&
      (siteConfig.siteConfig || props.smartfeedCompanyData) &&
      props.apiKey ? (
        <>
          <div
            className="banner"
            style={{
              backgroundRepeat: 'no-repeat',
              background: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(/banner.png)`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          ></div>
          <div className="container py-3">
            <div className="row">
              <div className="col-12 mb-3">
                <WigLoader
                  contentDataData={contentDataData}
                  contentMetaData={contentMetaData}
                  contentDataArray="0"
                  contentDataChild="0"
                  AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                  AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                  integratorID={config.integratorID}
                  integratorAccessToken={config.integratorAccessToken}
                  imageFormatting={
                    process.env.IMAGE_FORMATTING_SECRET ||
                    process.env.GATSBY_IMAGE_FORMATTING_SECRET
                  }
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="notfound">
          <section className="app-content">
            <div className="app-content-content">
              <div className="container">
                <div className="loading-panel">
                  <div className="loading-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <span className="loading-text">
                    Please wait... If content is not shown soon then you may
                    have the wrong URL.
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </Layout>
  );
}
